<script>
	import cn from 'classnames';
	import AdvantagesCarousel from './(carousel)/AdvantagesCarousel.svelte';
</script> 

<section class={cn('relative px-2 py-16', $$props.class)}>
	<div
		class="container mx-auto flex justify-center px-12 text-center text-lg text-gray-shark md:justify-between"
	>
			<AdvantagesCarousel/>
	</div>
</section>
