<script>
	import Carousel from 'svelte-carousel';
	import { browser } from '$app/environment';
	import box from '$lib/images/features/box.png';
	import thumb from '$lib/images/features/thumb.png';
	import message from '$lib/images/features/message.png';
	import service from '$lib/images/features/service.png';
	import CarouselArrow from '../CarouselArrow.svelte';

	let carousel;// for calling methods of the carousel instance
	let _particlesToShow = 4;
	let _showArrows = false;
	//if screensize is less than 768px, show only 1 item
	if (browser) {
		const currentWidth = window.innerWidth;
		if (currentWidth < 768) {
			_particlesToShow = 1;
			_showArrows = true;
		} else {
			_particlesToShow = 4;
			_showArrows = false;
		}
	}


	const showPrevPage = () => {
		carousel?.goToPrev();
	};

	const showNextPage = () => {
		carousel?.goToNext();
	};
</script>

{#if browser}
	<Carousel
		particlesToShow={_particlesToShow}
		dots={false}
		arrows={_showArrows}
		bind:this={carousel}
	>
		<div class="flex-col items-center gap-y-7  flex">
			<img src={box} alt="Zboží zasíláme ihned" />
			<p>Zboží zasíláme ihned</p>
		</div>
		<!-- tepmporary dummy hide -->
		<div class="flex flex-col items-center gap-y-7 ">
			<img src={thumb} alt="Po zadání skútru nemůžete koupit špatný díl" />
			<p>Po zadání skútru nemůžete<br />koupit špatný díl</p>
		</div>
		<!-- tepmporary dummy hide -->
		<div class=" flex flex-col items-center gap-y-7 ">
			<img src={message} alt="Rady a tipy od profíků" />
			<p>Rady a tipy od profíků<br />pro opravy svépomocí</p>
		</div>
		<!-- tepmporary dummy hide -->
		<div class="flex flex-col items-center gap-y-7 ">
			<img
				src={service}
				alt="Spolehlivý servis s rychlou online rezervací"
				
			/>
			<p>Spolehlivý servis s rychlou<br />online rezervací</p>
		</div>


		<div slot="prev">
			<CarouselArrow
				slot="left-control"
				orientation="left"
				class="absolute -left-0  top-[calc(5.625rem/2+4rem)] -translate-y-1/2"
				handleClick={() => showPrevPage()}
			/>
		</div>
		<div slot="next">
			<CarouselArrow
				slot="left-control"
				orientation="right"
				class="absolute -left-0  top-[calc(5.625rem/2+4rem)] -translate-y-1/2"
				handleClick={() => showNextPage()}
			/>
		</div>
	</Carousel>
{/if}
