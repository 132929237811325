<script lang="ts">
	import cn from 'classnames';
	import ChevronUpIcon from '$lib/images/svg/chevron-up.svelte';
	export let orientation: 'left' | 'right' = 'left';
	export let handleClick: () => void = () => {};
	export let isMobile: boolean = false;
	export let special: boolean = false;
</script>

<button
	class={cn(
		'flex items-center justify-center rounded-full bg-yellow ',
		isMobile
			? 'h-16 w-16 bg-opacity-75 text-black md:hidden'
			: 'h-10 w-10 text-white hover:bg-yellow-gamboge',
		$$props.class
	)}
	style="z-index: 1000;
    top: 50%;
    {orientation === 'left' ? isMobile && special ? 'left: -45px; right: auto;' :'left: -50px; right: auto;' : isMobile && special ? 'right: -45px; left: auto;' :'right: -50px; left: auto;'}"
	on:click={handleClick}
>
	<ChevronUpIcon
		class={cn(
			isMobile ? 'w-4' : 'w-5',
			orientation === 'left' ? '-rotate-90' : 'rotate-90',
			isMobile && orientation === 'left'
				? 'translate-x-3'
				: isMobile && orientation === 'right'
					? '-translate-x-3'
					: ''
		)}
	/>
</button>
